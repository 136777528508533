// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-credit-jsx": () => import("./../../../src/pages/credit.jsx" /* webpackChunkName: "component---src-pages-credit-jsx" */),
  "component---src-pages-exploration-compare-jsx": () => import("./../../../src/pages/exploration/compare.jsx" /* webpackChunkName: "component---src-pages-exploration-compare-jsx" */),
  "component---src-pages-explore-jsx": () => import("./../../../src/pages/explore.jsx" /* webpackChunkName: "component---src-pages-explore-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-subscribe-jsx": () => import("./../../../src/pages/subscribe.jsx" /* webpackChunkName: "component---src-pages-subscribe-jsx" */),
  "component---src-pages-subscribe-success-jsx": () => import("./../../../src/pages/subscribe-success.jsx" /* webpackChunkName: "component---src-pages-subscribe-success-jsx" */),
  "component---src-pages-vis-compare-jsx": () => import("./../../../src/pages/vis/compare.jsx" /* webpackChunkName: "component---src-pages-vis-compare-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

