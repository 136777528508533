import React, {useRef, useEffect} from "react";
import {Runtime, Inspector} from "@observablehq/runtime";
import notebook from "b3a06aa5487f9b6f";

function CompareSingleNotebook() {
  const ref = useRef();

  useEffect(() => {
    (new Runtime).module(notebook, name => {
      if (name === "viewof currentMetricName") return Inspector.into(ref.current.querySelector(".viewof-currentMetricName"))();
      if (name === "filterinfo") return Inspector.into(ref.current.querySelector(".filterinfo"))();
      if (name === "viewof timescale") return Inspector.into(ref.current.querySelector(".viewof-timescale"))();
      if (name === "viewof aggtype") return Inspector.into(ref.current.querySelector(".viewof-aggtype"))();
      if (name === "viewof timerange") return Inspector.into(ref.current.querySelector(".viewof-timerange"))();
      if (name === "statstable") return Inspector.into(ref.current.querySelector(".statstable"))();
      if (name === "viewof lineChart") return Inspector.into(ref.current.querySelector(".viewof-lineChart"))();
      if (name === "viewof variousCharts") return Inspector.into(ref.current.querySelector(".viewof-variousCharts"))();
      if (name === "apeekaround") return Inspector.into(ref.current.querySelector(".apeekaround"))();
      if (name === "viewof sensitivity") return Inspector.into(ref.current.querySelector(".viewof-sensitivity"))();
      if (name === "foundcomparisons") return Inspector.into(ref.current.querySelector(".foundcomparisons"))();
      if (name === "correlationstable") return Inspector.into(ref.current.querySelector(".correlationstable"))();
      if (name === "correlationschart") return Inspector.into(ref.current.querySelector(".correlationschart"))();
      if (name === "prettyneat") return Inspector.into(ref.current.querySelector(".prettyneat"))();
      return ["lineChart","currentMetric","daily_measurements","chartChunks","comparisons","variousCharts","metric_chart_data","regressionResults","timerange","allcorrelationstable","getChunks","getComparisons","allCorrelations","topCorrelationsAofA","bucketedTopCorrelations","topCorrelations","allcorrelationscharts","topCorrelationsToShow","topCorrelationsRThreshold"].includes(name);
    });
  }, []);

  return (
    <div className="Notebook" ref={ref}>
      <div className="viewof-currentMetricName"></div>
      <div className="filterinfo"></div>
      <div className="viewof-timescale"></div>
      <div className="viewof-aggtype"></div>
      <div className="viewof-timerange"></div>
      <div className="statstable"></div>
      <div className="viewof-lineChart"></div>
      <div className="viewof-variousCharts"></div>
      <div className="apeekaround"></div>
      <div className="viewof-sensitivity"></div>
      <div className="foundcomparisons"></div>
      <div className="correlationstable"></div>
      <div className="correlationschart"></div>
      <div className="prettyneat"></div>
    </div>
  );
}

export default CompareSingleNotebook;
