import React, {useRef, useEffect} from "react";
import {Runtime, Inspector} from "@observablehq/runtime";
import notebook from "ec4ea700fe295f5b";

function CategoriesNotebook() {
  const ref = useRef();

  useEffect(() => {
    (new Runtime).module(notebook, name => {
      if (name === "text1") return Inspector.into(ref.current.querySelector(".text1"))();
      if (name === "categorytable") return Inspector.into(ref.current.querySelector(".categorytable"))();
      if (name === "text2") return Inspector.into(ref.current.querySelector(".text2"))();
      if (name === "viewof timescale") return Inspector.into(ref.current.querySelector(".viewof-timescale"))();
      if (name === "viewof type") return Inspector.into(ref.current.querySelector(".viewof-type"))();
      if (name === "viewof chart") return Inspector.into(ref.current.querySelector(".viewof-chart"))();
      return ["chart"].includes(name);
    });
  }, []);

  return (
    <div className="Notebook" ref={ref}>
      <div className="text1"></div>
      <div className="categorytable"></div>
      <div className="text2"></div>
      <div className="viewof-timescale"></div>
      <div className="viewof-type"></div>
      <div className="viewof-chart"></div>
    </div>
  );
}

export default CategoriesNotebook;